@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Inter;
        font-weight: 100 900;
        font-style: normal;
        font-named-instance: Regular;
        src: url('../../../libs/ui/tailwindcss/src/lib/fonts/Inter-roman.var.woff2') format('woff2');
    }

    @font-face {
        font-family: Inter;
        font-weight: 100 900;
        font-style: italic;
        font-named-instance: Italic;
        src: url('../../../libs/ui/tailwindcss/src/lib/fonts/Inter-italic.var.woff2') format('woff2');
    }

    @font-face {
        font-family: 'Value Serif';
        font-weight: bold;
        font-style: normal;
        src: url('../../../libs/ui/tailwindcss/src/lib/fonts/value-serif-bold-pro.woff2') format('woff2');
    }

    @font-face {
        font-family: Caveat;
        font-weight: 400 700;
        font-style: normal;
        src: url('../../../libs/ui/tailwindcss/src/lib/fonts/Caveat-var-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
            U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    @font-face {
        font-family: Caveat;
        font-weight: 400 700;
        font-style: normal;
        src: url('../../../libs/ui/tailwindcss/src/lib/fonts/Caveat-var-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
            U+2C60-2C7F, U+A720-A7FF;
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        word-break: break-word;
        @apply text-grey-700;
    }
}

.group-inner:focus-within .group-inner-focus-within\:sr-only {
    @apply sr-only;
}

.group-inner:hover .group-inner-hover\:sr-only {
    @apply sr-only;
}

.group-inner:focus-within .group-inner-focus-within\:not-sr-only {
    @apply not-sr-only;
}

.group-inner:hover .group-inner-hover\:not-sr-only {
    @apply not-sr-only;
}

.group-inner:hover .group-inner-hover\:block {
    @apply block;
}

.group-inner:hover .group-inner-hover\:text-white {
    @apply text-white;
}

.group-inner:hover .group-inner-hover\:bg-blue-500 {
    @apply bg-blue-500;
}

.group-inner:hover .group-inner-hover\:bg-white {
    @apply bg-white;
}

.group-inner:hover .group-inner-hover\:transition {
    @apply transition;
}

.group-inner:hover .group-inner-hover\:text-salesforce {
    @apply text-salesforce;
}
.group-inner:hover .group-inner-hover\:ring-1 {
    @apply ring-1;
}
.group-inner:hover .group-inner-hover\:ring-grey-700\/10 {
    @apply ring-grey-700/10;
}

.desktop-drag {
    -webkit-app-region: drag;
}